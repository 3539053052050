<template lang="pug">
  v-container()
    v-row.align-baseline()
      v-col.pl-0()
        v-menu(ref='menuDate', :close-on-content-click='false', v-model='menuDate', :nudge-right='40', :return-value.sync='date',  transition='scale-transition', offset-y, , min-width='290px')
          template(#activator="{on}")
            v-text-field(v-on="on", v-model='formattedDate', :label='labelDate', prepend-icon='event', readonly,  :hide-details="true")
          v-date-picker(v-model='date', @input='$refs.menuDate.save();' :allowed-dates="allowedDates")
      v-col.pr-0(v-if="!hideTime")
        v-menu(ref="menuTime", :close-on-content-click='false', v-model="menuTime", :nudge-right="40", :return-value.sync='time', transition='scale-transition', offset-y, , min-width='290px')
          template(#activator="{on}")
            v-text-field(v-model="formattedTime"   :label='labelTime',  prepend-icon="access_time"  readonly v-on="on" :hide-details="true")
          v-time-picker(v-model="time", , @click:minute="$refs.menuTime.save()")
      v-btn(icon @click="onCancel")
        v-icon() close
</template>
<script>
import moment from "moment";

export default {
  props: ["value", "labelDate", "labelTime", "hideTime","onlyFuture"],
  data: () => ({
    menuDate: false,

    menuTime: false
  }),

  mounted() {
    if (this.value == "0001-01-01T00:00:00Z" || this.value == null) {
        let tempDefault = moment().toDate()
      this.$emit("input", tempDefault.toISOString());
    }



    this.labelDate = this.labelDate == null ? "Select Date" : this.labelDate;
    this.labelTime = this.labelTime == null ? "Select Time" : this.labelTime;


    // if (this.value === "0001-01-01T00:00:00Z" || this.value == null) {
    //   this.$emit("input", new Date().toISOString());
    // }

  },

  computed: {
    valueDate: {
      get: function () {
        // console.log(this.value);
        return new Date(this.value);
      },
      set: function (value) {
        // console.log("saving Date timestamp: " + value.getTime());
        this.$emit("input", value.toISOString());
      }
    },

    date: {
      get: function () {
        if (this.value == null) {
          return null;
        } else {
          return this.valueDate.toISOString().substr(0, 10);
        }
      },
      set: function (value) {
        if (value != undefined) {
          this.onSetDate(value);
        }
      }
    },

    time: {
      get: function () {
        if (this.value == null) {
          return null;
        } else {
          return moment(this.valueDate).format("HH:mm");
        }
      },
      set: function (value) {
        if (value != undefined) {
          this.onSetTime(value);
        }
      }
    },

    formattedTime: {
      get: function () {
        if (this.value == null) {
          return null;
        } else {
          return moment(this.valueDate).format("h:mm a");
        }
      },
      set: function () {
      }
    },

    formattedDate: {
      get: function () {
        if (this.value == null) {
          return null;
        } else {
          return moment(this.valueDate).format("DD MMMM YYYY");
        }
      },
      set: function () {
      }
    }
  },

  methods: {
    allowedDates: function(val){
        if (this.onlyFuture) {
            let theDate =  moment(val).add(1,"days").toDate()
            return theDate > new Date();
        }

        return true;
    },

    onCancel: function () {
      this.$emit("input", null)
    },
    onSetTime: function (value) {
      //initialize to current date onChange
      let t;
      if (this.value == null) {
        console.log("need to set the date to current");
        t = new Date();
      } else {
        t = this.valueDate;
      }

      // console.log("time is: " + value);

      t.setHours(
          parseInt(value.split(":")[0]),
          parseInt(value.split(":")[1]),
          0
      );

      this.valueDate = t;
    },
    onSetDate: function (value) {
      let t = this.valueDate;
      t.setFullYear(value.split("-")[0]);
      t.setMonth(value.split("-")[1] - 1);
      t.setDate(value.split("-")[2]);
      this.valueDate = t;

    }
  }
};
</script>
<style lang="stylus" scoped></style>
