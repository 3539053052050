<template lang="pug">
  div
    panel-title-header(label="Checklist Details")
    div.fill-width.mt-2.mb-2.pb-2
      table.DetailTable.body-2.fill-width
        tr(v-for="(item,index) in schema", :key="index" :class="getItem(item.id).passed ? '':['failRow','lighten-4']")
          template(v-if="item.name == 'TextField' || item.name == 'TextArea' ")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3
                  .grey--text.text--darken-4() {{item.label}}
                  .caption.grey--text.text-darken-2(v-if="item.hint" ) {{item.hint}}
            td()
              .pr-6 {{getItem(item.id).value}}
            td()
              div.px-2(v-if="!getItem(item.id).passed")
                v-icon.red--text() cancel
                .red--text fail
            td()
              .d-flex.justify-end()
                //--
                inspection-overview-comment.px-2(:items="getItem(item.id).comments" :max-width="201")
                .mx-2()
                inspection-overview-comment-photos.pr-2(:items="getItem(item.id).blobs" :max-width="200")



          template(v-else-if="item.name == 'Switch'")
            td
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3
                  .grey--text.text--darken-4() {{item.label}}
                  .caption.grey--text.text-darken-2(v-if="item.hint" ) {{item.hint}}
            td(:class="getItemSwitchLabel(item.id) == 'N/A' ? 'grey--text text--lighten-1':'grey--text text--darken-2 font-weight-bold text-md-body-1'")
              .px-2() {{getItemSwitchLabel(item.id)}}
            td
              div.px-2(v-if="!getItem(item.id).passed")
                v-icon.red--text() cancel
                .red--text fail

            td
              .d-flex.justify-end()
                inspection-overview-comment.px-2(:items="getItem(item.id).comments" :max-width="201")
                .mx-2()
                inspection-overview-comment-photos.pr-2(:items="getItem(item.id).blobs" :max-width="200")

          template(v-else-if="item.name == 'Date'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3
                  .grey--text.text--darken-4() {{item.label}}
                  .caption.grey--text.text-darken-2(v-if="item.hint" ) {{item.hint}}
            td
              .px-2() {{getItemDate(item.id) || ""}}
            td
              div.px-2(v-if="!getItem(item.id).passed")
                v-icon.red--text() cancel
                .red--text fail

            td
              .d-flex.justify-end()
                inspection-overview-comment.px-2(:items="getItem(item.id).comments" :max-width="201")
                .mx-2()
                inspection-overview-comment-photos.pr-2(:items="getItem(item.id).blobs" :max-width="200")


          template(v-else-if="item.name == 'DateTime'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3
                  .grey--text.text--darken-4() {{item.label}}
                  .caption.grey--text.text-darken-2(v-if="item.hint" ) {{item.hint}}
            td
              .px-2() {{getItemDateTime(item.id) || ""}}
            td
              div.px-2(v-if="!getItem(item.id).passed")
                v-icon.red--text() cancel
                .red--text fail

            td
              .d-flex.justify-end()
                inspection-overview-comment.px-2(:items="getItem(item.id).comments" :max-width="201")
                .mx-2()
                inspection-overview-comment-photos.pr-2(:items="getItem(item.id).blobs" :max-width="200")

          template(v-else-if="item.name == 'Time'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3
                  .grey--text.text--darken-4() {{item.label}}
                  .caption.grey--text.text-darken-2(v-if="item.hint" ) {{item.hint}}
            td
              .px-2() {{getItemTime(item.id) || ""}}
            td
              div.px-2(v-if="!getItem(item.id).passed")
                v-icon.red--text() cancel
                .red--text fail

            td
              .d-flex.justify-end()
                inspection-overview-comment.px-2(:items="getItem(item.id).comments" :max-width="201")
                .mx-2()
                inspection-overview-comment-photos.pr-2(:items="getItem(item.id).blobs" :max-width="200")

          template(v-else-if="item.name == 'Select' || item.name == 'Radio'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3
                  .grey--text.text--darken-4() {{item.label}}
                  .caption.grey--text.text-darken-2(v-if="item.hint" ) {{item.hint}}
            td
              .px-2() {{getItem(item.id).value}}
            td
              div.px-2(v-if="!getItem(item.id).passed")
                v-icon.red--text() cancel
                .red--text fail

            td
              .d-flex.justify-end()
                inspection-overview-comment.px-2(:items="getItem(item.id).comments" :max-width="201")
                .mx-2()
                inspection-overview-comment-photos.pr-2(:items="getItem(item.id).blobs" :max-width="200")

          template(v-else-if="item.name == 'Image' || item.name == 'Attach'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3
                  .grey--text.text--darken-4() {{item.label}}
                  .caption.grey--text.text-darken-2(v-if="item.hint" ) {{item.hint}}
            td()
              .d-flex()
                div(v-for="(blob,index) in getItem(item.id).value" :key="index")
                  blob-item-by-id(:id="blob" :size="getSchemaItem(item.id).image_size" :showDownloadMenuItem="true" @onMenuAction="onBlobAction" )
            td
              div.px-2(v-if="!getItem(item.id).passed")
                v-icon.red--text() cancel
                .red--text fail

            td
              .d-flex.justify-end()
                inspection-overview-comment.px-2(:items="getItem(item.id).comments" :max-width="201")
                .mx-2()
                inspection-overview-comment-photos.pr-2(:items="getItem(item.id).blobs" :max-width="200")


          template(v-else-if="item.name == 'Signature'" )
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3
                  .grey--text.text--darken-4() {{item.label}}
                  .caption.grey--text.text-darken-2(v-if="item.hint" ) {{item.hint}}
            td(style="max-width:150px;")
              .d-flex()
                blob-item-by-id(v-if="getItem(item.id).value" :id="getItem(item.id).value" :isSignature="true" size="small" :showDownloadMenuItem="true" @onMenuAction="onBlobAction" )
            td
              div.px-2(v-if="!getItem(item.id).passed")
                v-icon.red--text() cancel
                .red--text fail

            td
              .d-flex.justify-end()
                inspection-overview-comment.px-2(:items="getItem(item.id).comments" :max-width="201")
                .mx-2()
                inspection-overview-comment-photos.pr-2(:items="getItem(item.id).blobs" :max-width="200")

          template(v-else-if="item.name == 'Map' ")
            td.grey--text.text--darken-1
              .d-flex()
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3
                  .grey--text.text--darken-4() {{item.label}}
                  .caption.grey--text.text-darken-2(v-if="item.hint" ) {{item.hint}}
            td()
              .pr-6(v-if="getItem(item.id).value")
                .locationItem(@click="onShowMap(item.id)")
                  v-icon.mr-2() place
                  | {{getItem(item.id).value.lat.toFixed(5)}}, {{getItem(item.id).value.lon.toFixed(5)}}
            td()
              div.px-2(v-if="!getItem(item.id).passed")
                v-icon.red--text() cancel
                .red--text fail

            td()
              .d-flex.justify-end()
                inspection-overview-comment.px-2(:items="getItem(item.id).comments" :max-width="201")
                .mx-2()
                inspection-overview-comment-photos.pr-2(:items="getItem(item.id).blobs" :max-width="200")


          template(v-else-if="item.name == 'Header'")
            td.grey.lighten-3.px-3.primary--text.text-subtitle-1.font-weight-bold(colspan=4) {{item.label}}

          template(v-else-if="item.name == 'Table'")
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3
                  .grey--text.text--darken-4() {{item.label}}
                  .caption.grey--text.text-darken-2(v-if="item.hint" ) {{item.hint}}
            td
              table.tableRender(v-if="getItem(item.id).value")
                // Header Rows

                tr()
                  th(v-for="(column,index) in getSchemaItem(item.id).columns" :key="index" :style="getTableHeaderStyle(column)") {{column.label}}
                // Data rows
                tr(v-for="(row,rowIndex) in getItem(item.id).value" :key="rowIndex")
                  td(v-for="(itemColumn,itemIndex) in getSchemaItem(item.id).columns" :key="itemColumn")
                    template(v-if="itemColumn.type == 'Checkbox'" )
                      v-checkbox.ma-0(v-model="row[itemColumn.id]" hide-details dense  disabled)
                    template(v-else-if="itemColumn.type == 'Signature'" )
                      blob-item-by-id(v-if="row[itemColumn.id]" :id="row[itemColumn.id]" :isSignature="true" size="small" :showDownloadMenuItem="true"  )
                    template(v-else)
                      div() {{row[itemColumn.id]}}

            td
              div.px-2(v-if="!getItem(item.id).passed")
                v-icon.red--text() cancel
                .red--text fail

            td
              .d-flex.justify-end()
                inspection-overview-comment.px-2(:items="getItem(item.id).comments" :max-width="201")
                .mx-2()
                inspection-overview-comment-photos.pr-2(:items="getItem(item.id).blobs" :max-width="200")
          template(v-else)
            td.grey--text.text--darken-1
              .d-flex
                .red-dot(v-if="getSchemaItem(item.id).required")
                .ml-3
                  .grey--text.text--darken-4() {{item.label}}
                  .caption.grey--text.text-darken-2(v-if="item.hint" ) {{item.hint}}
            td
              .px-2() {{getItem(item.id).value}}
            td
              div.px-2(v-if="!getItem(item.id).passed")
                v-icon.red--text() cancel
                .red--text fail

            td
              .d-flex.justify-end()
                inspection-overview-comment.px-2(:items="getItem(item.id).comments" :max-width="201")
                .mx-2()
                inspection-overview-comment-photos.pr-2(:items="getItem(item.id).blobs" :max-width="200")

</template>

<script>
import PanelTitleHeader from "@/modules/global/components/others/PanelTitleHeader";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import BlobItemById from "@/modules/uploads/components/BlobItemById";
import MapDialog from "@/modules/global/components/table/MapDialog";
import InspectionOverviewComment
  from "@/modules/inspections/components/overview/components/InspectionOverviewComment";
import InspectionOverviewCommentPhotos
  from "@/modules/inspections/components/overview/components/InspectionOverviewCommentPhotos";
import moment from "moment";

export default {
  props: {
    schema: Array,
    record: Object,
    inspectionForm: Object,
  },

  data: () => ({
    coordinates: null,
    radius: null,
    mapLabel: null,
    zoom: 0,
  }),
  components: {
    PanelTitleHeader,
    ImageThumbnail,
    BlobItemById,
    MapDialog,
    InspectionOverviewComment,
    InspectionOverviewCommentPhotos
  },

  mounted() {
    console.log(this.schema)
  },
  methods: {
    getItem: function (id) {
      // console.log(this.schema.find(schemaItem => schemaItem.id = id))
      return this.record.data[id];
    },
    getItemSwitchLabel: function (id) {
      let theSchemaItem = this.schema.find(obj => obj.id == id);
      if (theSchemaItem.required) {
        return this.record.data[id].value ? theSchemaItem.options[0] : theSchemaItem.options[1];
      }else{
        if (this.record.data[id].value == null) {
          return "N/A"
        }else{
          return this.record.data[id].value ? theSchemaItem.options[0] : theSchemaItem.options[1];
        }

      }
    },
    getItemDate: function (id) {
      if (this.record.data[id].value == null) {
        return false
      }
      let theSchemaItem = this.schema.find(obj => obj.id == id);
      return moment(this.record.data[id].value).format(theSchemaItem.date_format)
    },
    getItemTime: function (id) {
      if (this.record.data[id].value == null) {
        return false
      }
      let theSchemaItem = this.schema.find(obj => obj.id == id);
      if (theSchemaItem.time_format === "ampm") {
        return moment(this.record.data[id].value, "HH:mm").format("LT")
      } else {
        return moment(this.record.data[id].value, "HH:mm").format("HH:mm")
      }
    },

    getItemDateTime: function (id) {
      if (this.record.data[id].value == null) {
        return false
      }
      return moment(this.record.data[id].value).format("ddd, Do MMM YYYY, h:mm a")
    },

    getSchemaItem: function (id) {
      let theSchemaItem = this.schema.find(obj => obj.id == id);
      return theSchemaItem
    },

    onBlobAction: function (value) {
      console.log(value)
    },

    onWheel(event) {
      if (event.deltaY < 0) {
        this.zoom += 1;
      } else if (event.deltaY > 0) {
        this.zoom -= 1;
      }
    },


    onShowMap(id) {

      let location = this.record.data[id].value;
      this.$store.commit("showMap", location)

    },
    onCloseMap() {
      this.$store.commit("hideMap");
    },
    getTableHeaderStyle: function(itemSchema){
      console.log(itemSchema.align)
      let theStyle = {'text-align':itemSchema.align};
      return theStyle;
    },

    getTableRowStyle:function(itemSchema,index){
      let theStyle = {'text-align':itemSchema.align};
      if(index%2 == 0){
        theStyle["background-color"] = "#f2f2f2"
      }else{
        theStyle["background-color"] = "none"
      }

      return theStyle;
    },
  }

}
</script>

<style lang="stylus" scoped>
.checklistText {
  max-width: 40%;
}

.locationItem {
  display flex;
  background-color #E8E8E8;
  border-radius 8px;
  align-items center
  padding 5px 8px;
  cursor pointer;
  max-width 200px;
}

.locationItem:hover {
  background-color #d3d3d3;
}

.red-dot {
  background-color #FF0000;
  width 4px;
  height 4px;
  border-radius 50%;
  margin-right -8px;
  margin-left 5px;
  margin-top 5px;
}
.DetailTable
  tr
    margin 0
    padding 0
  td
    border-bottom 1px solid #EEEEEE
    margin 0
    padding 0

    padding-top 6px
    padding-bottom 8px
    .locationItem {
      display flex;
      background-color #E8E8E8;
      border-radius 8px;
      align-items center
      padding 5px 8px;
      cursor pointer;
      max-width 200px;
    }

.locationItem:hover {
  background-color #d3d3d3;
}

.tableRender{
  //table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 2px solid #6a6a6a;
}
.tableRender th{
  //border-bottom: 1px solid #E8E8E8;
  //border-left : 1px solid #E8E8E8;
  padding: 2px 10px 4px 10px;
  font-size: 11px;
  color: #afafaf;
}
.tableRender td{
  //border-bottom: 1px solid #E8E8E8;
  //border-left : 1px solid #E8E8E8;
  padding: 2px 10px;
  border-radius 3px;
  font-size: 12px;
  color: #4c4b4b;
}
.failRow {
  background-color: #fcf0f0;
}

</style>