<template lang="pug">
  div()
    div(v-for="(item,index) in items" :key="index")
      .failComment.caption(:style="{'max-width': maxWidth + 'px'}") {{item.value}}
</template>
<script>
export default {

  props: {
    items: Array,
    maxWidth: {
      type: Number,
      default: 200,
    }
  },
}
</script>
<style scoped lang="stylus">
.failComment {
  color: #3b3b3b;
}
</style>