var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2 mb-2 pb-2"},[_c('table',{staticClass:"DetailTable"},[_c('tr',[_vm._m(0),_c('td',[_vm._v(_vm._s(_vm.currentVehicle.name))])]),_c('tr',[_vm._m(1),_c('td',{staticClass:"flexSpaceBetween pr-4"},[_vm._v(_vm._s(_vm.currentVehicle.id))])]),(_vm.currentVehicle.group)?_c('tr',[_vm._m(2),_c('td',{staticClass:"flexSpaceBetween pr-4"},[_vm._v(" "+_vm._s(_vm.currentVehicle.group.name)),_c('v-spacer'),_c('div',{staticClass:"handCursor primary--text",on:{"click":function($event){return _vm.onClick('groupHistory')}}},[_vm._v(" History")])],1)]):_vm._e(),_c('tr',[_vm._m(3),_c('td',{staticClass:"flexSpaceBetween pr-4"},[_vm._v(" "+_vm._s(_vm.currentVehicle.status)),_c('div',{staticClass:"handCursor primary--text",on:{"click":function($event){return _vm.onClick('activeHistory')}}},[_vm._v("History")])])]),_c('tr',[_vm._m(4),_c('td',{staticClass:"flexSpaceBetween pr-4"},[_vm._v(" "+_vm._s(_vm.currentVehicle.vin))])]),_c('tr',[_vm._m(5),_c('td',[_vm._v(_vm._s(_vm.currentVehicle.number_plate))])]),_c('tr',[_vm._m(6),_c('td',[_vm._v(_vm._s(_vm.currentVehicle.year))])]),_c('tr',[_vm._m(7),_c('td',[_vm._v(_vm._s(_vm.currentVehicle.make))])]),_c('tr',[_vm._m(8),_c('td',[_vm._v(_vm._s(_vm.currentVehicle.model))])]),_c('tr',[_vm._m(9),_c('td',[_vm._v(_vm._s(_vm.currentVehicle.series))])]),_c('tr',[_vm._m(10),_c('td',[_vm._v(_vm._s(_vm.currentVehicle.color))])]),_c('tr',[_vm._m(11),_c('td',[_vm._v(" "+_vm._s(_vm.currentVehicle.ownership))])]),_c('tr',[_vm._m(12),_c('td',[_vm._v(_vm._s(_vm.currentVehicle.body))])]),_c('tr',[_vm._m(13),_c('td',[_vm._v(_vm._s(_vm.currentVehicle.body_sub_type))])]),_c('tr',[_vm._m(14),_c('td',[_vm._v(_vm._s(_vm._f("money")(_vm.currentVehicle.msrp)))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Vehicle ID")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Group")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Status")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("VIN/SN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Number Plate")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Year")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Make")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Model")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Trim")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Color")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Ownership")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Body Type")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("Body Sub-Type")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"goRight grey--text text--darken-1"},[_c('div',{staticClass:"mr-3"},[_vm._v("MSRP")])])
}]

export { render, staticRenderFns }