<template lang="pug">
    .image-grid(:style="{'max-width': maxWidth + 'px'}")
      div( v-for="(item,index) in items" :key="index")
        blob-item-by-id(:id="item" size="x-small")
</template>
<script>
import BlobItemById from "@/modules/uploads/components/BlobItemById";
export default {
  props:{
    items:Array,
    maxWidth:{
      type:Number,
      default:200
    }
  },
  components:{
    BlobItemById,
  }

}
</script>



<style scoped lang="stylus">
.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 equal columns */
  gap: 10px; /* Space between images */ /* Adjust as needed */
  margin: auto;
}

</style>